@media (max-width: 800px) {
    
}

/* Small devices (tablets, 767px and up) */
@media (max-width: $small-devices) {

   .top-header_right{

   }
   .table-scrollable {
      overflow-x: scroll;
   }
}