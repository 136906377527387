@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

@font-face {
  font-family: 'Font-awesome';
  src: url('../libs/font-awesome/fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),  url('../fonts/fontawesome-webfont.woff') format('woff'), url('../fonts/fontawesome-webfont.ttf')  format('truetype'), url('../fonts/fontawesome-webfont.svg#font-awesome') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Light';
  src: url('../fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Light.otf')  format('opentype'),
  url('../fonts/ProximaNova-Light.woff') format('woff'), url('../fonts/ProximaNova-Light.ttf')  format('truetype'), url('../fonts/ProximaNova-Light.svg#ProximaNova-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Regular.otf')  format('opentype'),
	     url('../fonts/ProximaNova-Regular.woff') format('woff'), url('../fonts/ProximaNova-Regular.ttf')  format('truetype'), url('../fonts/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Semibold';
  src: url('../fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Semibold.otf')  format('opentype'),
  url('../fonts/ProximaNova-Semibold.woff') format('woff'), url('../fonts/ProximaNova-Semibold.ttf')  format('truetype'), url('../fonts/ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Bold.otf')  format('opentype'),
	     url('../fonts/ProximaNova-Bold.woff') format('woff'), url('../fonts/ProximaNova-Bold.ttf')  format('truetype'), url('../fonts/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Black';
  src: url('../fonts/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Black.otf')  format('opentype'),
  url('../fonts/ProximaNova-Black.woff') format('woff'), url('../fonts/ProximaNova-Black.ttf')  format('truetype'), url('../fonts/ProximaNova-Black.svg#ProximaNova-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* MEDIA QUERIES SCREEN SIZES */
$xsmall-devices		: 480px;
$small-devices		: 767px;
$medium-devices		: 991px;
$large-devices		: 1199px;
$xlarge-devices		: 1280px;

/* URL's */
$img-url			: '../images/';

/* Fonts / Sizes */
$font-default		      : 'ProximaNova-Regular';
$font-proxinova-light     : 'ProximaNova-Light';
$font-proxinova-bold      : 'ProximaNova-Bold';
$font-proxinova-semibold  : 'ProximaNova-Semibold';
$font-proxinova-black     : 'ProximaNova-Black';
$font-roboto              : 'Roboto';


/* Primary COLORS */
$colo-default		: #212121;
$dark-gray			: #fdfdfd;
$blue               : #1194f5;
$powder-blue        : #b9ddfb;
$orange		        : #ff5607;
$green              : #00A84A;

/* Status Message COLORS */
$error-color		: #ed171f;
$success-color      : #e74c3c;


$enable-flex: true;

/* Global Styles */
html{ 
	overflow-y: scroll;	
}
body{
	background-color: #fff;
	font-family: $font-default !important;
}
*{
    outline: none !important;
}
.container .row {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

a{
    text-decoration: none !important;
}
.clearBoth{
    clear: both;
    float: none;
}
h1{
    color: $colo-default ;
    font-family: $font-proxinova-light;
    font-size: 32px;
    margin-bottom: 10px;
    margin-top: 36px;
    text-transform: capitalize;
}
h2{
    color: $colo-default ;
    font-family: $font-proxinova-light;
    font-size: 28px;
    margin-bottom: 20px;
}
h3{
    color: $colo-default ;
    font-family: $font-proxinova-light;
    font-size: 24px;
    margin-bottom: 20px;
}
h4{
    color: $colo-default ;
    font-family: $font-proxinova-light;
    font-size: 20px;
    margin-bottom: 20px;
}
h5{
    color: $colo-default ;
    font-family: $font-proxinova-light;
    font-size: 18px;
    margin-bottom: 20px; 
}
h6{
    color: $colo-default ;
    font-family: $font-proxinova-light;
    font-size: 16px;
    margin-bottom: 20px; 
}

.page__content{   
    
    >p, >h2, >h3, >h4, >h5, >h6{
        margin-top: 0;
    }
    p{
        color: $colo-default;
        font-family: $font-default;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 19px;
        
        a{
            color: $blue;
        }
    } 
    strong{
        color: $dark-gray;
    }
    .post__featured{
        margin-bottom: 0;
    }
    
    /* Featured Image*/
    
    img{
        float: left;
        margin-bottom: 20px;
        margin-right: 30px;
        margin-top: 4px;
        max-width: 100%;
        height: auto;
    }
    
    /* Order/ UnOrder list*/
    >ul{
        margin-left: 95px;
        margin-bottom: 20px;        
        
    }
    
    ul{
        overflow: hidden;
        list-style: none;
        >li{
            color: $colo-default;
            font-family: $font-default;
            font-size: 12px;
            margin-top: 14px;
            margin-bottom: 0;
            
            &:before{
                color: $dark-gray;
                content: "\f101";
                font-family: FontAwesome;
                margin-left: -38px;
                margin-right: 5px;
            }
            
            a{
                color: $blue;
                text-decoration: none;
            }
            
            &:first-child{
                margin-top: 0;
            }
        }
        
        
    }
    
    ol{ 
        @extend ul;
        list-style: outside decimal;
        
        >li{
            &:before{
                content:"";
                margin: 0;
            }
        }
        ol{
            list-style: outside lower-alpha;
            padding-left: 20px;            
            
        }
    }
    
    .attachment-contact-thumb{
        float: none;
        margin-right: 0;
    }
    
}

.btn{
	margin-bottom: 12px;
	font-family: $font-proxinova-semibold;

  &.btn-blue{
    background-color: $blue;
  }

  &.btn-orangle{
    background-color: $orange;
  }

}
.btn + .btn{
	margin-left: 15px;
}

button + button {
    margin-left: 15px;
}


.cyx-breadcrumb{
    background: none;
    margin-top: -11px;
    margin-bottom: 2px;
    padding-left: 0;
    color: $font-default;
    font-family: $font-default;
    font-weight: bold;
    font-size: 15px;
    
    i{
        margin: 0 2px;
        color: $blue;
    }
    
    span{
        color: $font-default;
        font-family: $font-default;
        font-size: 15px;
        margin-left: 1px;
        margin-right: 3px;
        
       
    }
    a {
        color: $blue;
        font-family: $font-default;
        font-size: 15px;
        
        span{
            color: $blue;
            font-family: $font-default;
            font-size: 15px;
        }
    }
}

.pagination{
    text-align: right;
    .page-numbers{
        color: #7d7d7d;
        background: none;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        display: inline-block;
        font-family: $font-default;
        font-size: 15px;
        text-align: center;
        padding: 2px 11px;
        text-decoration: none;
        margin-right: 5px;
        
        &.prev{
            margin-right: 32px;
        }
        &.next{
            margin-left: 32px;
        }
            
        &.current{
            background-color: $blue;
            border: 1px solid $blue;
            color: #ffffff;
        } 
        
        &:hover{
            background-color: $blue;
            border: 1px solid $blue;
            color: #ffffff;
        }
    }
}

.full-height-viewport{
	height: 100vh;
    min-height: 600px;
}
.cl-flex{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.cl-flex-center{
  justify-content: center;
}
.cl-flex-middle{
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}
.cl-text-center{
  text-align: center !important;
}

/*---------------------------------------------
     [System messages]
----------------------------------------------*/
.flash-message-container{
  background: #00A94A;
  text-align: center;
  opacity: 0.9;
  color: #FFFFFF;
  line-height: 40px;
  border-radius: 5px;
  border: 1px solid #00A94A;
}
.cl-system-messages{
	font-size: 14px;
	font-weight: normal;
}
.cl-alert {
	background-color: #e1f2fb;
	background-position: 15px center;
	background-repeat: no-repeat;
	border-radius: 2px;
	color: #fff;
	margin-bottom: 20px;
	padding: 10px 10px 10px 50px;
	text-align: left;
}
.error-message {
    background-color: $error-color;
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
    color: #fff;
}
.success-message {
    background-color: $success-color;
	border: solid 1px #d0e9c6;
	text-align: center;
  	color: #fff;
	font-weight: normal;
	font-size: 16px;
	margin: 10px 0;
	padding: 10px;
}
.warning-message {
    background-color: #f89406;	
	text-align: center;
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
    color: #fff;
}
.info-message {
    background-color: #2f96b4;
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
    color: #fff;
}


/*---------------------------------------------
   [Animations]
--------------------------------------------*/
@keyframes cl-shake {
	0%, 100% {
		transform: translateX(0px);
	}
	10% {
		transform: translateX(-9px);
	}
	20% {
		transform: translateX(8px);
	}
	30% {
		transform: translateX(-7px);
	}
	40% {
		transform: translateX(6px);
	}
	50% {
		transform: translateX(-5px);
	}
	60% {
		transform: translateX(4px);
	}
	70% {
		transform: translateX(-3px);
	}
	80% {
		transform: translateX(2px);
	}
	90% {
		transform: translateX(-1px);
	}
}
[class*="clss-animation-"] {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
}

.cl-animation-shake {
    animation-name: uk-shake;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  transition: .3s ease-out;
}

/*-------------------------
   Form Validations
---------------------------*/
.form-validaion{
	label.error{
		color: $error-color;
		font-family: $font-default;
		font-size: 12px;
		line-height: normal;
		padding: 0;
	}
}

//Switch Button
.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;

  &:before {
    content: "ON";
    width: 10px;
    top: 6px;
    left: 10px;
    position: relative;
    font-size: 13px;
    color: #fff;
    z-index: 9;
  }
  &:after {
    content: "OFF";
    width: 10px;
    top: 6px;
    left: 25px;
    position: relative;
    font-size: 13px;
    color: #fff;
  }

  input {
    display:none;

    &:checked + .slider {
      background-color: #a6e50f;
    }
    &:focus + .slider {
      box-shadow: 0 0 1px #a6e50f;
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(40px);
      -ms-transform: translateX(40px);
      transform: translateX(40px);
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #939ea7;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 8px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
  }
}

table{
  th{
    background-color: #f1f4f7;
    color: #3f444a;
    font-size: 14px;
    font-weight: normal;
  }

  tbody{
    tr:nth-of-type(2n+1) {
      background-color: #fbfcfd !important;
    }

    tr{
      td{
        color: #3f444a;
        font-size: 14px;
        font-weight: normal;
      }

      &:hover{
        background-color: #f3f4f6 !important;
      }
    }
  }
}

/*------------------------
  Pagination
--------------------------*/
.pagination{
  text-align: center;
  display: table;
  margin: 0 auto;

  span{
    color: #212121;
    background: none;
    border: 1px solid #e6e6e6;
    display: table-cell;
    font-family: $font-default;
    font-size: 16px;
    text-align: center;
    padding: 6px 16px;
    text-decoration: none;
    border-right: 1px solid #eceeef;
    border-top: 1px solid #eceeef;
    border-bottom: 1px solid #eceeef;
    border-left: 0;
    cursor: pointer;

    a{
      color: #212121;
      padding: 0;
    }

    &::after, &::before{
      display: none !important;
      width: 0;
    }

    &:first-child{
      border-left: 1px solid #eceeef;
      border-radius: 4px 0 0 4px;
      font-size: 12px;
    }
    &:last-child{
      border-radius: 0 4px 4px 0;
      font-size: 12px;
    }

    &.current{
      background-color: #00A94A;
      border: 1px solid #00A94A;
      color: #ffffff;

      &:after, &:before {
        display: none !important;
      }
    }

    &:hover{
      background-color: #00A94A;
      border-color:#00A94A;
      a{
        color: #ffffff;

      }
    }
  }
}

//.flash-notice {
//  background: #FF0000;
//  text-align: center;
//  opacity: 0.5;
//  color: #FFFFFF;
//  line-height: 40px;
//  border-radius: 5px;
//  border-radius: 5px;
//  border: 1px solid #8B0000;
//}

.gsi-step-indicator {
  background-color: #edeff0;
  border-radius: 0.25em;
  margin: 1em auto;
  padding: 0 1.2em;
  width: 100%;

  &:after {
    clear: both;
    content: "";
    display: table;
  }

  &.triangle {
    background-color: transparent;
    padding: 0;

    li {
      margin: 4px 4px 4px 0;
      padding: 0;
      position: relative;

      &:last-of-type {
        margin-right: 0;
        > * {
          border-radius: 0 0.25em 0.25em 0;
          padding-right: 1.6em;

          &:after {
            display: none;
          }
        }
      }
      &:first-of-type > * {
        border-radius: 0.25em 0 0 0.25em;
      }
      &:last-of-type::after {
        display: none;
      }

      &:after {
        margin: 0;
        -webkit-transform: translateX(4px);
        -moz-transform: translateX(4px);
        -ms-transform: translateX(4px);
        -o-transform: translateX(4px);
        transform: translateX(4px);
        z-index: 1;
        -moz-border-bottom-colors: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        border-color: transparent;
        border-left-color: #fff;
        border-image: none;
        border-style: solid;
        border-width: 31px 0 31px 20px;
        content: "";
        height: 0;
        left: 100%;
        position: absolute;
        top: 2px;
        width: 0;
      }
      > * {
        background-color: #edeff0;
        border-color: #edeff0;
        color: #2c3f4c;
        padding: 10px 19px 10px 25px !important;
        position: relative;

        &:after{
          z-index: 2;
          border-left-color: inherit;
        }
      }
      &.current > * {
        background-color: #00A84A;
        border-color: #00A84A;
        color: #fff;
      }

      a{
        &:hover {
          background-color: #2c3f4c;
          border-color: #2c3f4c;
          color: #fff;
        }
      }

    }
  }

  li {
    display: inline-block;
    float: left;
    margin: 1.2em 0;

    &:after {
      color: #959fa5;
      content: "»";
      display: inline-block;
      margin: 0 1em;
    }
    > * {
      transition: background-color 0.6s ease 0s, border-color 0.6s ease 0s;
      display: flex;
      font-size: 1.4rem;

      &:after {
        -moz-border-bottom-colors: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        border-color: transparent;
        border-image: none;
        border-style: solid;
        border-width: 31px 0 31px 20px;
        content: "";
        height: 0;
        left: 100%;
        position: absolute;
        top: 2px;
        width: 0;
      }
    }

    .number{
      font-size: 1.5em;
    }
    .desc {
      padding-left: 10px;
      vertical-align: super;

      label {
        font-size: 18px;
        display: inline-block;
        font-weight: 700;
        max-width: 100%;
      }
      span {
        display: block;
        font-size: 14px;
      }
    }
  }
}