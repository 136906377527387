footer{
  margin-top: 80px;
  border-top: 10px solid #00A94A;

  .pre-footer{
    background-color: #48525e;
    color: #a2abb7;
    padding: 40px 0;

    h2{
      color: #32c5d2;
      font-size: 15px;
      font-weight: 700;
      text-transform: uppercase;
    }
    p{
      font-size: 14px;
      color: #a2abb7;
    }
    .pre-footer_address{
      font-size: 14px;
      color: #a2abb7;
    }
  }

  .footer{
    background: #3b434c none repeat scroll 0 0;
    color: #a2abb7;
    padding: 20px 0;
    font-size: 14px;

    a{
      color: #ffffff;
    }
  }


  .scrollup{
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 9999;
    color: #657383;
    font-size: 40px;
    cursor: pointer;
  }
}