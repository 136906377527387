header{
  .navbar {
    .fa-bars{
      color:white;
    }

    .navbar-toggle {
      float: none;
    }

    .navbar-nav li{
      span.dropdown-toggle {
        position: relative;
        &:after {
          content: '\35';
          font-size: 30px;
          font-family: 'ElegantIcons';
          color:white;
        }
      }
      &.open {
        &>span.dropdown-toggle {
          &:after {
            content: '\33';
          }
        }
      }
      .dropdown-menu {
        background-color: #231f20;
        li:last-child {
          border-bottom: none;
        }
        a {
          border: none;
          &:hover,
          &:active,
          &:focus{
            background-color: inherit;
            border: none;
          }
        }
      }

      a{
        display:inline-block;
      }
    }
  }

  .top-header{
    padding: 20px 0;
  }
  //Top Nav
  .nav-header{
    background-color: #444d58;
  }
  #top-menu{
    .navbar{
      padding: 0;
    }
    .navbar-toggler{
      display: none;
    }
    .collapse{
      display: block;

      ul{
        margin: 0;
        padding: 0;
        list-style: none;

        li{
          margin: 0;
          a{
            padding: 16px 18px 15px;
            color: #ffffff;

            &:hover{
              background-color: #55616f;
            }
          }
        }
      }
    }
  }

  .top-header_profile{

    a{
      color: #7089a2;
      font-size: 14px;
    }
    .dropdown{
      position: relative;

      .dropdown-menu{
        background: #2e343b none repeat scroll 0 0;
        border: 0 none;
        width: 195px;
        border-radius: 0;
        right: 0;
        left: auto;
        padding: 0;

        &:before{
          border-bottom: 8px solid #efefef;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          content: "";
          display: inline-block !important;
          position: absolute;
          right: 9px;
          top: -8px;
        }
        :after {
          border-bottom: 7px solid #2e343b;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          content: "";
          display: inline-block !important;
          position: absolute;
          right: 10px;
          top: -7px;
        }

        li{
          a{
            color: #aaafb7;
            font-size: 13px;
            font-weight: 300;
            padding: 11px 16px;
            display: block;

            span {
              margin-right: 7px;
            }

            &:hover{
              background-color: #373e47;
            }
          }
        }
        li + li{
          a{
            border-top: 1px solid #3b434c !important;
          }
        }
      }
    }

    img{
      border-radius: 30px;
      height: 40px;
      margin-right: 7px;
      width: 40px;
    }
  }

}

