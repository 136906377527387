/*------------------------------------------------------------------
	[  Header ]
-------------------------------------------------------------------*/
.page-breadcrumb{
 background:none;
 border-radius: 0;
 border-bottom: 1px solid #e7ecf1;
 margin-bottom: 50px;

  >li{
   display: inline-block;
  }
 li{
  font-size: 14px;
  color: #a7b2c0;
  a{
   color: #00A84A;
   font-size: 14px;
  }

  i{
   font-size: 9px;
   margin-left: 7px;
  }
 }
}

.action-buttons{
  display: table;
  margin-bottom: 30px;
  margin-top: 50px;
   .btn{
    border-radius: 0;
    font-size: 14px;
    background-color: #3598dc;
    color: #fff;
    display: table-cell;
    margin: 0 !important;

    &:hover, &.active{
     background-color: #1f78b5;
    }
   }
}

.blue-box {
  border: 1px solid #60aee4;
  border-top: 0;
  background-color: #3598dc;
  font-size: 14px !important;
  margin-top: 20px;
  padding: 0 !important;
  .blu-box-title {
    background-color: #3598dc;
    border: 1px solid #3598dc;
    border-bottom: 0;
    padding: 0 10px;
    margin-bottom: 0;
    color: #fff;
    clear: both;
    content: " ";
    display: -webkit-box;
    h3 {
      margin-top: 20px;
      margin-bottom: 10px;
      color: inherit;
      font-weight: 300;
      display: block;
    }

  }
  .blu-box-form {
    margin-top: 0;
    margin-bottom: 25px;
    background-color: #fff;
    padding: 0;
    form {
      border: 1px solid #3598dc;
      .form-body {
        padding: 15px 30px;
        label.control-label {
          font-size: 16px;
          font-weight: 600;
        }
        fieldset {
          border: 0 !important;
          padding: 0;
          margin: 0;

        }
        .form-actions {
          padding: 20px;
          .btn {
            margin: 0;
            color: #FFF;
          }
          .btn-next {
            float: right;
          }
        }
        .radio-inline {

        }
      }

    }
  }
}
/* Base for label styling */
[type="checkbox"]:not(:checked),
 [type="checkbox"]:checked {
   position: absolute;
   left: -9999px;
 }
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  color: #212121;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left:0;
  top: 2px;
  width: 20px;
  height: 20px;
  border: 1px solid #bbb;
  background: #fff;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
  outline: none;
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: '✔';
  position: absolute;
  top: 8px;
  left: 5px;
  font-size: 14px;
  line-height: 0.8;
  color: $green;
  transition: all .2s;
  outline: none;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}
/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
  border: 1px dotted blue;
}
[type="checkbox"] + label {
  display: block;
}


  .radio-inline{
    padding-left: 20px;
  }


/*-------------------------
    [Radio Buttons Style]
--------------------------*/
[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: absolute;
  left: -9999px;
}
[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  color: #212121;
  padding-top: 3px;
}

/* checkbox aspect */
[type="radio"]:not(:checked) + label:before,
[type="radio"]:checked + label:before {
  content: '';
  position: absolute;
  left:0;
  top: 2px;
  width: 20px;
  height: 20px;
  border: 1px solid #bbb;
  border-radius: 100%;
  background: #fff;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
  outline: none;
}
/* checked mark aspect */
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:after {
  content: '\2022';
  position: absolute;
  line-height: 0.8;
  color: $green;
  transition: all .2s;
  outline: none;
  top: -9px;
  left: 2px;
  font-size: 46px;
}
/* checked mark aspect changes */
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
[type="radio"]:disabled:not(:checked) + label:before,
[type="radio"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
[type="radio"]:disabled:checked + label:after {
  color: #999;
}
[type="radio"]:disabled + label {
  color: #aaa;
}
/* accessibility */
[type="radio"]:checked:focus + label:before,
[type="radio"]:not(:checked):focus + label:before {
  border: 1px dotted blue;
}
[type="radio"] + label {
  display: block;
}


/*------------------------------------------------------------------
	[  Login Form ]
-------------------------------------------------------------------*/
.login-background{
  //background-image: url("../images/bk/anil-bg-1.jpg");
  //background-size: cover;
  //background-repeat: no-repeat;
}
#login-container{
  padding: 33px 0 17px;
  width: 100%;
  border-radius: 5px;

  .login-container-inner{
    background-color: #fff;
    border-radius: 5px;
  }
  .login-bg{
    border-radius: 0 5px 5px 0;
  }
  .login-box{
    padding: 50px 70px;

    .small-logo{
      margin-bottom: 15px;
    }
  }

  .login-content{
    padding: 70px 70px;
    text-align: left;
    p{
      color: #989898;
    }

    h3{
      font-family: $font-proxinova-semibold;
    }

    .update-text{
      margin-bottom: 40px;
    }
    .question-text{

      >div{
        background-color: #f9fbfc;
        width: 100%;
        color: #989898;
        padding: 7px 20px;
        margin-bottom: 3px;
        font-size: 16px;

        a{
          color: #989898;
          font-size: 16px;

          &.email-i{
            color: #56a2fd;
            text-decoration: underline !important;
          }
        }
        i{
          margin-right: 7px;
          color: #989898 !important;
        }
        .fa-mobile{
          font-size: 25px;
        }
      }
    }
  }

  .login-logo{
    box-sizing: border-box;
    height: auto;
    max-width: 100%;
    margin-bottom: 16px;
    font-size: 40px;
    color: #58B957;
    font-family: $font-proxinova-bold;
    text-align: center;
    width: 100% !important;
  }
  .login-title{
    width: 299px;
    margin: 0 auto 24px;
    max-width: 100%;
    font-family: $font-default;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    text-align: center;
    text-transform: uppercase;
  }
  .cl-system-messages{
    width: 299px;
    margin: 0 auto;
    max-width: 100%;

    .cl-alert-danger{
      background: #fff;
      color: $error-color !important;
    }
  }
  .login-form{
      .red-error{
        li{
          color: $error-color !important;
          float: left;
          margin-bottom: 10px;
          margin-top: 3px;
        }
      }
    .login-panel{
      color: #999999;

      .form-fields + .form-fields {
        margin-top: 15px;
      }
      .input-username{
        font-family: $font-default;
        border: 1px solid #d8d8d8;
        border-radius: 5px;
        background: #fff;
        color: #666;
        height: 47px;
        font-size: 16px;
        padding: 0 15px;
        max-width: 100%;
        transition-delay: 0s;
        transition-duration: 0.2s;
        transition-property: border, background, color, box-shadow, padding;
        transition-timing-function: linear;
        text-align: left;
        width: 100%;
      }
      .input-password{
        @extend .input-username;
      }
      .input-email{
        @extend .input-username;
      }
      .login-btn-section{
        width: 299px;
        max-width: 100%;
        margin-bottom: 28px;

        .login-btn{
          width: 100%;
          border-radius:5px;
          padding-bottom: 5px;
          padding-top: 5px;
          background-color: $green;
          height: 39px;
          min-height: 39px !important;
          line-height: normal;
          font-size: 12px;
          font-weight: bold;
          font-family: $font-proxinova-bold;
          color: #fff;
          text-transform: uppercase;
          border: none;

        }
      }
    }

    ul{
      list-style: none;
      margin-bottom: 0;
      padding: 0;

      li{
        font-family: $font-default;
        font-size: 14px;
        font-weight: normal;
      }

      li + li{
        margin-top: 5px;
      }

      a{
        text-decoration: underline !important;
        cursor: pointer;
        font-family: $font-default;
        font-size: 14px;
        font-weight: normal;
        color: #000;

      }
      #registration-link{
        margin-left: 8px;
      }
    }
  }
  .forgot-pwd-form{
    @extend .login-form;
  }

  /* Base for label styling */
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
  }
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 23px;
    cursor: pointer;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left:0;
    top: 2px;
    width: 20px;
    height: 20px;
    border: 1px solid #bbb;
    background: #fff;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
    outline: none;
  }
  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    content: '✔';
    position: absolute;
    top: 8px;
    left: 5px;
    font-size: 14px;
    line-height: 0.8;
    color: #a6e50f;
    transition: all .2s;
    outline: none;
  }
  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  [type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  /* disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + label:before,
  [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
  }
  [type="checkbox"]:disabled:checked + label:after {
    color: #999;
  }
  [type="checkbox"]:disabled + label {
    color: #aaa;
  }
  /* accessibility */
  [type="checkbox"]:checked:focus + label:before,
  [type="checkbox"]:not(:checked):focus + label:before {
    border: 1px dotted blue;
  }

  /* hover style just for information */
  label:hover:before {
    border: 1px solid #4778d9!important;
  }
}
th {
  vertical-align: top !important;
}
.center-align{
  text-align: center;
}

.bored-blue{
  border-color: #0275d8 !important;
  background-color: transparent;
  width: 100%;
  font-size: 14px !important;
  margin-bottom: 0;
  a{
    margin-right: 7px;
  }
}
.login-container-inner{
  .cl-alert{
    background-color: #fff !important;
    color: #000 !important;
    padding: 15px 0 !important;
  }
}
/*-------------------------------------------------------------------
          [ login/registration page background slider]
-------------------------------------------------------------------------*/



.full-banner {
  position:relative;
  background-color: #ffffff;


  .slick-prev, .slick-next {
    width: 50px;
    height: 50px;
    top: 50%;
    z-index: 999;
    position: absolute;
    cursor: pointer;
    background: #261d20 ;
    -moz-opacity: 0.8;
    -khtml-opacity: 0.8;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:before{
      font-family: 'ElegantIcons';
      color: white;
      font-size: 24px;
    }
  }

  .slick-prev {
    left: 15px;
    &:before{
      content: "\34";
    }
  }

  .slick-next {
    right: 15px;
    &:before{
      content: "\35";
    }
  }

  .slick-dots {
    background: transparent;
    position: absolute;
    bottom: 20px;
  }
  .banner-layer{
    position: absolute;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
}

.banner-item{
  height: 100vh !important;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}

.login-main-content{
  position: absolute;
  left: 0;
  width: 100%;
  top: 10%
}

.field-left-alrt{
  margin-bottom: 10px;
  background-color: #FF0000;
  opacity: 0.5;
  color: #fff;
  text-align: center;
  font-size: 18px;
  padding: 15px;
  margin-bottom: 10px;
}

.back-to-home{
  text-decoration: underline !important;
  cursor: pointer;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-weight: normal;
  color: #000;
}