/* Medium devices (desktops, 991px and up) */
@media (max-width: $medium-devices) {
	/*------------------------------------------------------------------
		[ HEADER ]
	-------------------------------------------------------------------*/
	// code here...
    .navbar {
        .navbar-nav li{
            span.dropdown-toggle {
                &:after {
                    display:none;
                }
            }

        }
        .headr-social-icons{
            text-align: center;
        }
    }
    
    
    
    /*************** 
     * Enable Responsive Menu 
     ***************************/
    header #top-menu .navbar-toggler {
        display: block;
    }
    header #top-menu .navbar-toggler {
        cursor: pointer;
        display: inline;
        margin: 15px;
    }
    
    .navbar .navbar-collapse #menu-top-menu .dropdown-toggle {
        display: block !important;
        padding: 0px 30px;
    }
    
    .navbar {
        .navbar-header{ 
            display: block;
            float: none;
            margin-top: 0px;
            
            .navbar-toggle {
                background: none;  
                border: none;
                display: block;
                margin: 0 auto;
                text-align: center;
                i{
                    color: #ffffff;
                }
                
            }
            
            > a.navbar-brand{
                display: none;
            }
        }
        .navbar-collapse{
                border: none !important;
                display: none;
                position: relative;
                left: 0px;
                width: 100%;
                padding: 0px;
                

                &.collapse{
                    display: none !important;
                } 

                &.in{
                    display: block !important;
                }
                
                /*************** 
                 * Style Responsive Menu 
                 ***************************/
                #menu-top-menu{
                    float: none;
                    padding: 0px;
                    
                    >li {
                        > a{
                          color: #ffffff;  
                            display: block;
                        }
                        
                        &:hover, &.current-menu-item, &.current-menu-item{
                            >a{
                                color: #ffffff;
                            }
                        }
                    }
                    
                    li{
                        display: block;
                        float: none;
                        
                        span.dropdown-toggle{
                            &.arrow{
                                position: absolute !important;
                                right: 0;
                                top: 8px;
                            }
                            &:after {
                                display: block;
                            }
                        }
                        &:hover{
                            .dropdown-menu{
                                display: none;
                                background: none;
                            }
                        }
                        &.open{
                            >a{
                                background: none;
                            }
                            >.dropdown-menu{
                                display: block;
                            }
                        }
                        
                        .dropdown-menu{
                            position: relative !important;
                            width: 100%;
                            box-shadow: none;
                            background: none;
                            
                            li{ 
                                position: relative;
                                a{
                                    display: inline-block;  
                                    color: #fff !important;
                                    font-size: 18px;
                                    font-family: $font-default;

                                }
                                &:hover{
                                    > a{
                                       color: #000 !important; 
                                    }
                                }
                            }
                            
                            .dropdown-toggle{
                                &.arrow{
                                    position: absolute !important;
                                    right: 0;
                                    top: 8px;
                                }
                                &:after{
                                    color: #fff !important;
                                    line-height: normal;
                                    padding-top: 3px;
                                }
                            }
                            
                            .dropdown-menu{
                                left: 0px !important;
                                
                                a{
                                    padding: 4px 20px 4px 30px !important;
                                    
                                }
                                
                            }
                        }
                    }
                }
                
                
                
            }

    }
    
    .navbar .navbar-collapse #menu-top-menu > li.menu-item-has-children:hover::after{
        border: 0px;
    }
	 .navbar .navbar-collapse #menu-top-menu > li > a{
	 	border-left: medium none;
        padding: 13px;
    }
     
    /*------------------------------------------------------------------
		[ BANNER ]
	-------------------------------------------------------------------*/
    
   
    
	/*------------------------------------------------------------------
		[ CONTENT ]
	-------------------------------------------------------------------*/
   
}