/*------------------------------------------------------------------
	[ STYLESHEET ]
	Project:		Project Name
	Version:		6.5.18
	Last change:	06.18.15 [Created]
	Assigned to:	Titan Web (Your Name)

	[ TABLE OF CONTENTS ]
	1.  VARIABLES
	2.  MIXINS
	3.  COMPONENTS
	4.  HEADER
	5.  CONTENT
	6.  FOOTER
	7.  XSMALL DEVICES
	8.  SMALL DEVICES
	9.  MEDIUM DEVICES
	10.  LARGE DEVICES
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
	[ 1. VARIABLES ]
-------------------------------------------------------------------*/
@import 'variables/_variables';

/*------------------------------------------------------------------
	[ 2. MIXINS ]
-------------------------------------------------------------------*/
@import 'partials/_mixins';

/*------------------------------------------------------------------
	[ 3. COMPONENTS ]
-------------------------------------------------------------------*/
@import 'partials/_components';

/*------------------------------------------------------------------
	[ 4. HEADER ]
-------------------------------------------------------------------*/
@import 'partials/_header';

/*------------------------------------------------------------------
	[ 5. CONTENT ]
-------------------------------------------------------------------*/
@import 'partials/_content';

/*------------------------------------------------------------------
	[ 6. FOOTER ]
-------------------------------------------------------------------*/
@import 'partials/_footer';


/*------------------------------------------------------------------
	[ 10. LARGE DEVICES ]
	(large desktops, 1199px and below)
-------------------------------------------------------------------*/
@import 'partials/_large-devices';

/*------------------------------------------------------------------
	[ 9. MEDIUM DEVICES ]
	(desktops, 991px and below)
-------------------------------------------------------------------*/
@import 'partials/_medium-devices';

/*------------------------------------------------------------------
	[ 8. SMALL DEVICES ]
	(tablets, 767px and below)
-------------------------------------------------------------------*/
@import 'partials/_small-devices';

/*------------------------------------------------------------------
	[ 7. XSMALL DEVICES ]
	(tablets, 480px and below)
-------------------------------------------------------------------*/
@import 'partials/_xsmall-devices';