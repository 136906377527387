@media (max-width: 640px) {
	#login-container .login-box {
	  padding: 50px 30px;
	}
}

/* Small devices (tablets, 480px and up) */
@media (max-width: $xsmall-devices) {

}
